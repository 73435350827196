import { useQuery } from "@tanstack/react-query"
import { doc, getDoc } from "firebase/firestore"
import { firestore } from "../firebase"

export function ProfileName({ id }: { id: string }) {
  const { isLoading, data } = useQuery<any>({
    queryKey: ['profile', id],
    queryFn: () => getDoc(doc(firestore, 'profiles', id)).then(
      s => s.data()
    ),
    enabled: !!id,
  })
  if (isLoading) return '...'
  return data?.name
}