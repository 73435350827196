// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  // connectFirestoreEmulator,
  getFirestore,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBQ4iNvn4h2qzjMxdLvvSbp-x3nX_k2yC0",
  authDomain: "alamogordo-realift.firebaseapp.com",
  projectId: "alamogordo-realift",
  storageBucket: "alamogordo-realift.appspot.com",
  messagingSenderId: "932207328319",
  appId: "1:932207328319:web:30ff3404b7da53dc450c8a",
  measurementId: "G-ESN76XD2Z5"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfEcA4qAAAAADfxQK4h6RsVxz-muKe4sOlgCDmI"),
  isTokenAutoRefreshEnabled: true,
});
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

// connectFirestoreEmulator(firestore, '127.0.0.1', 8080);