import { useEffect, useState } from "react"

export function Duration({ start, end }: { start: number, end: number }): string {
  const [duration, setDuration] = useState(0)
  useEffect(() => {
    if (start && end) {
      setDuration(end - start)
    } else if (start) {
      const interval = setInterval(() => {
        setDuration((Date.now() / 1_000) - start)
      }, 1_000)
      return () => clearInterval(interval)
    }
  }, [start, end])
  if (!start) return '~s'
  return duration.toFixed(0) + 's'
}