import { Badge, Button, FormLayout, Grid, MediaCard, Page, Popover, Select, InlineStack, Modal, LegacyStack, ChoiceList } from "@shopify/polaris";
import { QueryConstraint, collection, deleteDoc, doc, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, updateDoc, writeBatch } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { firestore } from "../firebase";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useArray } from "../utils/useArray";
import { MediaPoster } from "../components/media";
import { ProfileName } from "../components/profileName";
import { AutomationIcon, DeleteIcon, PlayIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";

export function Recordings() {
  const recordings = useArray<any>([])
  useEffect(() => {
    const queryConstraints: QueryConstraint[] = [orderBy("timestamp", "desc"), limit(100)];
    // if (filter) {
    //   if (filter === 'unread')
    //     queryConstraints.unshift(
    //       where("answered", "==", false)
    //     );
    // }
    const unsubscribe = onSnapshot(
      query(
        collection(firestore, "recordings"),
        ...queryConstraints
      ),
      (snapshot) =>
        snapshot
          .docChanges()
          .reverse()
          .forEach((change) => {
            const recording = {
              id: change.doc.id,
              ...change.doc.data(),
            } as any;
            recordings.removeById(recording.id);
            if (change.type !== "removed") recordings.unshift(recording);
          })
    );
    return () => {
      recordings.clear();
      return unsubscribe();
    };
  }, []);
  const { isLoading: profileLoading, data: profiles } = useQuery({
    queryKey: ['profiles'],
    queryFn: () => getDocs(collection(firestore, 'profiles')).then(
      s => [{ value: '', label: 'Select profile' }].concat(s.docs.map(d => ({ value: d.id, label: d.data().name })))
    ),
    refetchOnWindowFocus: false,
  });
  const [profile, setProfile] = useState<string | undefined>()
  const qrCode = useMemo(() => {
    const url = new URL('https://qr.realift.io/')
    const app = new URL('https://realfoot.getrealift.com/record')
    app.searchParams.set('id', profile!)
    url.searchParams.set('chl', app.toString())
    return url.toString()
  }, [profile])
  const [popoverActive, setPopoverActive] = useState(!true);
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );
  const activator = (
    <Button onClick={togglePopoverActive} loading={profileLoading}>
      Add new Recording
    </Button>
  );

  const toggleTestList = useCallback((id: string, testList: boolean) => updateDoc(doc(firestore, 'recordings', id), { testList }), [])

  const deleteOne = useCallback((id: string) => window.confirm('Are you sure you want to delete this recording?') && deleteDoc(doc(firestore, 'recordings', id)), [])

  const setRecordingProfile = useCallback((id: string, profile: string) => updateDoc(doc(firestore, 'recordings', id), { profile }), [])

  const navigate = useNavigate()
  const runAllMutation = useMutation<void, Error, { recordings: any[], environment: string }>({
    mutationFn: ({ recordings, environment }) => {
      console.log(JSON.stringify(recordings, null, 2))
      const batch = writeBatch(firestore)
      recordings.forEach(
        recording => {
          batch.set(
            doc(collection(firestore, 'runs')),
            {
              environment,
              profile: recording.profile,
              recording: recording.id,
              status: 0,
              timestamp: serverTimestamp()
            }
          )
        }
      )
      return batch.commit()
    },
    onSuccess: () => navigate('/runs')
  })
  const [runAllEnvironment, setRunAllEnvironment] = useState('main');
  const runAll = useCallback(() => runAllMutation.mutate({ recordings: recordings.value, environment: runAllEnvironment }), [recordings, runAllEnvironment])

  const [runAllActive, setRunAllActive] = useState(false);

  return <Page
    title="Recordings"
    primaryAction={<Popover
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
      ariaHaspopup={false}
      sectioned
    >
      <FormLayout>
        <Select label="Select profile" options={profiles} value={profile} onChange={setProfile} />
        {profile && <img
          src={qrCode}
          style={{ width: 250, display: 'block', aspectRatio: '1/1' }}
        />}
      </FormLayout>
    </Popover>}
    secondaryActions={[
      {
        content: 'Run all',
        onAction: () => setRunAllActive(true),
        loading: runAllMutation.isPending,
      }
    ]}
  >
    <Modal
      instant
      size="small"
      open={runAllActive}
      onClose={() => setRunAllActive(false)}
      title="Please select environment"
      primaryAction={{
        content: 'Run',
        onAction: runAll,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => setRunAllActive(false),
        },
      ]}
    >
      <Modal.Section>
        <LegacyStack vertical>
          <LegacyStack.Item>
            <Select
              label="Environment"
              options={[
                { label: 'Main', value: 'main' },
                { label: 'Staging', value: 'staging' },
              ]}
              value={runAllEnvironment}
              onChange={setRunAllEnvironment}
            />
          </LegacyStack.Item>
        </LegacyStack>
      </Modal.Section>
    </Modal>
    <Grid>
      {
        recordings.value.map(
          recording => <Grid.Cell key={recording.id} columnSpan={{ xs: 3, sm: 2, md: 3, lg: 3, xl: 3 }}>
            <MediaCard
              title={recording.profile ? <ProfileName id={recording.profile} /> : <Select label options={profiles} onChange={(selected) => setRecordingProfile(recording.id, selected)} />}
              // primaryAction={{
              //   content: 'Run now',
              //   onAction: () => { },
              // }}
              description={<InlineStack gap="100">
                {' #' + recording.id}
                {recording.testList && <Badge tone="info">Test list</Badge>}
                <Badge>{recording.model}</Badge>
                <Badge>{recording.browser}</Badge>
              </InlineStack> as any}
              popoverActions={[
                { content: 'Run now', icon: PlayIcon, url: '/runs/add?profile=' + recording.profile + '&recording=' + recording.id },
                { content: (recording.testList ? 'Remove' : 'Add') + ' to Test List', icon: AutomationIcon, /** , suffix: <Icon source={recording.testList ? CheckIcon : XIcon} /> */ onAction: () => toggleTestList(recording.id, !recording.testList) },
                { content: 'Delete', icon: DeleteIcon, destructive: true, onAction: () => deleteOne(recording.id) }
              ]}
              portrait
              size="small"
            >
              <MediaPoster source={recording.recording} />
            </MediaCard>
          </Grid.Cell>
        )
      }
    </Grid>
  </Page>
}