import { createContext, useCallback, useContext, useState } from "react";
import { useLocalStorage } from "./localStorage";

type UnitValue = 'cm' | 'in';

const UnitContext = createContext<[
  UnitValue,
  () => void
]>(null!);

export const useUnit = () => useContext(UnitContext);

export function UnitProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [unit, setUnit] = useLocalStorage<UnitValue>("unit", "cm");

  const handleToggle = useCallback(() => setUnit((v) => v === "cm" ? "in" : "cm"), [unit]);

  return <UnitContext.Provider value={[unit, handleToggle]} children={children} />;
}