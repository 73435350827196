import '@shopify/polaris/build/esm/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import { ThemeProvider, AppProvider, Button, ButtonGroup, EmptyState, Frame, Navigation, Page } from '@shopify/polaris';
import { LogoGoogleIcon, PlusCircleIcon } from '@shopify/polaris-icons';
import { Outlet, Link as ReactRouterLink, RouterProvider, createBrowserRouter, redirect, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { type LinkLikeComponentProps } from '@shopify/polaris/build/ts/src/utilities/link';
import { RunsList } from './pages/runs/list';
import { AddRun } from './pages/runs/add';
import { ProfilesList } from './pages/profiles/list';
import { ProfilesEdit } from './pages/profiles/edit';
import { Run } from './pages/runs/run';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Recordings } from './pages/recordings';
import { auth } from './firebase';
import { useCallback } from 'react';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { UnitProvider, useUnit } from './unit';

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;
const Link: React.ComponentType<LinkLikeComponentProps> = function ({ children, url = '', external, ...rest }) {
  // react-router only supports links to pages it can handle itself. It does not
  // support arbirary links, so anything that is not a path-based link should
  // use a reglar old `a` tag
  if (IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = '_blank';
    rest.rel = 'noopener noreferrer';
    return (
      <a href={url} {...rest} >
        {children}
      </a>
    );
  }
  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

const queryClient = new QueryClient()

function Root() {
  const location = useLocation()
  const [unit, toggleUnit] = useUnit()
  return <Frame
    navigation={<Navigation location={location.pathname}>
      <Navigation.Section
        items={[
          {
            label: "Runs",
            url: "/runs",
            secondaryActions: [{
              url: '/runs/add',
              accessibilityLabel: 'Add a new Run',
              icon: PlusCircleIcon,
              tooltip: {
                content: 'Add a new Run',
              },
            }],
          }
        ]}
      />
      <Navigation.Section
        title="State"
        items={[
          {
            label: "Profiles",
            url: "/profiles",
          },
          {
            label: "Recordings",
            url: "/recordings",
          },
        ]}
      />
      <div style={{ margin: '1rem' }}>
        <ButtonGroup variant="segmented">
          <Button
            pressed={unit === "cm"}
            onClick={toggleUnit}
          >
            cm
          </Button>
          <Button
            pressed={unit === "in"}
            onClick={toggleUnit}
          >
            in
          </Button>
        </ButtonGroup>
      </div>
      <Navigation.Section
        separator={true}
        items={[
          {
            label: "Logout",
            url: "/login",
            onClick: async () => {
              signOut(auth)
              // redirect("/login")
            },
          },
        ]}
      />
    </Navigation>}
  >
    <Outlet />
  </Frame>
}

function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()
  const login = useCallback(() => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result)!;
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log('user', user)
        navigate(searchParams.get('return') || '/')
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        alert(errorMessage)
        // ...
      });
  }, [searchParams])
  return <Page>
    <EmptyState
      heading="Alamogordo - Realift"
      action={{ content: 'Login', onAction: login, icon: LogoGoogleIcon }}
      // secondaryAction={{
      //   content: 'Learn more',
      //   url: 'https://help.shopify.com',
      // }}
      image="/emptystate-files.png"
    >
      <p>This application is restricted for internal use only.</p>
    </EmptyState>
    {/* <div style={{ display: 'flex', height: '100svh' }}>
      <BlockStack inlineAlign="center" align="center">
        <Button size="large" icon={LogoGoogleIcon} onClick={login}>Login</Button>
      </BlockStack>
    </div> */}
  </Page>
}

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Root />,
    async loader({ request }) {
      await auth.authStateReady()
      if (!auth.currentUser) {
        const url = new URL(request.url);
        const searchParams = new URLSearchParams();
        searchParams.set("return", url.pathname + url.search);
        throw redirect("/login?" + searchParams.toString());
        // throw redirect("/login");
      }
      return true;
    },
    children: [
      {
        path: "/",
        loader: () => redirect('/runs'),
      },
      {
        path: "/runs",
        element: <RunsList />,
      },
      {
        path: "/runs/add",
        element: <AddRun />,
      },
      {
        path: "/run/:id",
        element: <Run />,
      },
      {
        path: "/profiles",
        element: <ProfilesList />
      },
      {
        path: "/profiles/:id",
        element: <ProfilesEdit />
      },
      {
        path: "/recordings",
        element: <Recordings />
      }
    ]
  },
]);

function App() {
  return (
    <UnitProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme="light">
          <AppProvider i18n={enTranslations} linkComponent={Link}>
            <RouterProvider router={router} />
          </AppProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </UnitProvider>
  );
}

export default App;