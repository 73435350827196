import { Avatar, Card, Page, ResourceItem, ResourceList, Text } from "@shopify/polaris";
import { useArray } from "../../utils/useArray";
import { useEffect } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import { firestore } from "../../firebase";

export function ProfilesList() {
  const profiles = useArray<any>([])
  useEffect(() => {
    // const queryConstraints: QueryConstraint[] = [orderBy("timestamp", "desc"), limit(25)];
    // if (filter) {
    //   if (filter === 'unread')
    //     queryConstraints.unshift(
    //       where("answered", "==", false)
    //     );
    // }
    const unsubscribe = onSnapshot(
      query(
        collection(firestore, "profiles"),
        // ...queryConstraints
      ),
      (snapshot) =>
        snapshot
          .docChanges()
          .reverse()
          .forEach((change) => {
            const run = {
              id: change.doc.id,
              ...change.doc.data(),
            } as any;
            profiles.removeById(run.id);
            if (change.type !== "removed") profiles.unshift(run);
          })
    );
    return () => {
      profiles.clear();
      return unsubscribe();
    };
  }, []);
  return <Page title="Profiles" primaryAction={{
    content: 'Add profile',
    url: '/profiles/add'
  }}>
    <Card padding="0">
      <ResourceList
        resourceName={{ singular: 'profile', plural: 'profiles' }}
        items={profiles.value}
        renderItem={(profile) => <ResourceItem
          key={profile.id}
          id={profile.id}
          url={"/profiles/" + profile.id}
          media={<Avatar initials={profile.name[0]} size="xl" name={profile.name} />}
          accessibilityLabel={`View details for ${profile.name}`}
        >
          <Text variant="bodyMd" fontWeight="bold" as="h3">
            {profile.name}
          </Text>
          <div>{profile.gender}</div>
        </ResourceItem>}
      />
    </Card>
  </Page>
}