import { useQuery } from "@tanstack/react-query"
import { doc, getDoc } from "firebase/firestore"
import { firestore } from "../firebase"
import { useMemo } from "react"
import { UnitValue } from "./unitValue"
import { Badge } from "@shopify/polaris"

export function Diff({ id, measurements }: { id: string, measurements: any }) {
  const { isLoading, data } = useQuery<any>({
    queryKey: ['profile', id],
    queryFn: () => getDoc(doc(firestore, 'profiles', id)).then(
      s => s.data()
    ),
    enabled: !!id,
  })
  const profileMeasurements = useMemo(() => {
    if (!data) return
    return Object.fromEntries(Object.entries(data.feet).map(([key, value]) => ([key, parseFloat(value as string)])))
  }, [data])
  if (isLoading) return '...'
  if (!profileMeasurements) return '!'
  if (!measurements) return ''
  // return [profileMeasurements.length / measurements.foot_length, profileMeasurements.width  / measurements.foot_width].map((s) => ((1 - s) * 100).toFixed(0)).join('/') + ' %'
  return <>
    <UnitValue value={profileMeasurements.length - measurements.foot_length} noSufix />/
    <UnitValue value={profileMeasurements.width - measurements.foot_width} />
    {" "}
    <Badge>{[profileMeasurements.length / measurements.foot_length, profileMeasurements.width / measurements.foot_width].map(v => (v * 100 - 100).toFixed(0)).join('/') + '%'}</Badge>
  </>
}