import { VideoThumbnail } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";
import { create, windowScheduler, indexedResolver } from "@yornaath/batshit";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

const batcher = create({
  // The fetcher resolves the list of queries(here just a list of user ids as number) to one single api call.
  fetcher: async (ids: string[]) => {
    return httpsCallable<string[], string[]>(functions, 'media', { limitedUseAppCheckTokens: true })(ids).then(
      result => Object.fromEntries(
        result.data.map((link: string, i: number) => [ids[i], link])
      )
    )
  },
  // when we call users.fetch, this will resolve the correct user using the field `id`
  resolver: indexedResolver(),
  // this will batch all calls to users.fetch that are made within 10 milliseconds.
  scheduler: windowScheduler(10),
})

const TTL = 1_000 * 60 * 3

export function MediaPoster({ source }: { source: string }) {
  const { isPending, data: poster } = useQuery<any>({
    queryKey: ['mediaPoster', source],
    gcTime: TTL,
    queryFn: () => Promise.all([
      // batcher.fetch(source + '.webm'),
      batcher.fetch(source + '.webp'),
    ]).then(([poster]) => poster),
    staleTime: TTL,
    refetchOnWindowFocus: false,
    // refetchOnWindowFocus(query) {
    //   query.state
    //   return false

    // },
  })
  // useEffect(() => {
  //   batcher.fetch(source).then(setSrc)
  //   if (source.endsWith('.webm'))
  //     batcher.fetch(source.replace('webm', 'webp')).then(setPoster)
  // }, [])

  // if (isPending) return <div>Loading...</div>

  return <VideoThumbnail
    // videoLength={4}
    thumbnailUrl={poster}
    onClick={() => console.log('clicked')}
  />

  // return <video src={data?.src} poster={data?.poster} style={{ width: '100%', display: 'block' }} preload="none" loop />
}

export function Video({ source }: { source: string }) {
  const { isPending, data } = useQuery<any>({
    queryKey: ['media', source],
    gcTime: TTL,
    queryFn: () => Promise.all([
      batcher.fetch(source + '.webm'),
      batcher.fetch(source + '.webp'),
    ]).then(([src, poster]) => ({ src, poster })),
    staleTime: TTL,
    refetchOnWindowFocus: false,
    // refetchOnWindowFocus(query) {
    //   query.state
    //   return false

    // },
  })
  return <img style={{ width: '100%', display: 'block' }} src={data?.poster} />
  return <video style={{ width: '100%', display: 'block' }} poster={data?.poster} src={data?.src} autoPlay muted loop controls={false} />
}